.PreviewTop {
  flex: 1;
  background: transparent;
  padding: 40px 25px 104px 25px;


  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;

  .__logo {
    width: 100px;
    height: 100px;
    border-radius: 1px;
    margin: 0 0 30px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .__title {
    color: inherit;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    margin: 0 0 54px 0;
  }

  .__details {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 628px;
    margin: 0 0 55px 0;

    .__details__body {
      flex: 1;
      display: flex;
      padding: 62px 34px 28px 34px;
      border-radius: 37px 37px 0 0;
      border: 1px solid #dfdfdf;
      border-bottom: none;

      .__info {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 75%;
        color: inherit;
        overflow-wrap: break-word;

        .__info__num {
          font-weight: 500;
          font-size: 20px;
          line-height: 18px;
          margin: 0 0 18px 0;

          .__label {
          }
          .__value {
            font-size: 19px;
            font-weight: 400;
          }
        }

        .__info__name {
          font-weight: 700;
          font-size: 54px;
          line-height: 54px;
          margin: 0 0 22px 0;
        }

        .__info__pos {
          font-weight: 300;
          font-size: 20px;
          line-height: 18px;
          margin: 0 0 27px 0;
        }

        .__info__org {
          font-weight: 300;
          font-size: 20px;
          line-height: 18px;
        }

        .__key {
          text-transform: capitalize;
        }
      }

      .__qr {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 25%;
        background-color: white;
        padding: 10px;
        height: fit-content;

        img {
          width: 117px;
        }
      }
    }

    .__details__type {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      background-color: #ff8a00;
      border-radius: 0 0 37px 37px;

      color: #f8f8fa;
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
    }
  }

  .__date {
    color: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1.2px;
    max-width: 400px;
    margin: 0 0 20px 0;
  }

  .__who {
    color: inherit;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1.2px;
  }
}
