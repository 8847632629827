.EventGallery {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: -10px;
}

.EventGalleryItem {
  display: flex;
  flex-direction: column;
  width: 220px;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.1), 0 16px 32px -4px rgba(145, 158, 171, 0.1);
  overflow: hidden;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  margin: 10px;
  cursor: pointer;

  &:hover,
  &.__selected {
    box-shadow: 0 0 2px 0 rgba(145, 158, 171, 0.3), 0 16px 32px -4px rgba(145, 158, 171, 0.3);
  }

  &.__selected &__preview {
    .__checkbox {
      svg {
        opacity: 1;
      }
    }
  }

  &__preview {
    position: relative;
    width: 100%;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 55, 64, 0.04);
    margin: 0 0 10px 0;

    .__checkbox {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 10px;
      z-index: 9;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: #00ab55;
        width: 70px;
        height: auto;
        opacity: 0;
      }
    }

    img {
      flex: 1;
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      width: auto;
      height: auto;
      margin: auto;
    }

    svg {
      width: 50px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px 3px 10px;
    height: 40px;

    font-family: "Poppins", sans-serif;

    .__name {
      color: #333333;
      font-size: 13px;
      line-height: 15px;
      font-weight: 500;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .__meta {
      color: rgb(158, 167, 184);
      font-size: 11px;
      line-height: 15px;
      font-weight: 400;
    }
  }
}

.GalleryModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  &__modal {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    background-color: #fff;
    width: 90vw;
    height: 80vh;
    overflow: hidden;
    border-radius: 3px;
    z-index: 9;
  }

  &__content {
    flex: 1;
    padding: 50px;
    height: calc(80vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #363636;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    height: 58px;
    min-height: 58px;
    max-height: 58px;
    box-shadow: 0px -1px 1px rgba(82, 82, 82, 0.1);

    .__actions {
      .__yes-btn {
        background-color: #00ab55;
        box-shadow: 0px 8px 16px rgba(82, 82, 82, 0.15);
        min-width: 100px;
        height: 36px;

        .MuiButton-label {
          color: #fff;

          .ball-loader {
            &-ball {
              background-color: #fff;
            }
          }
        }

        &.Mui-disabled {
          background-color: rgba(145, 158, 171, 0.8);
        }
      }

      .__no-btn {
        background-color: transparent;
        box-shadow: unset;
        min-width: 100px;
        margin: 0 5px 0 0;

        .MuiButton-label {
          color: #363636;
        }
      }
    }
  }
}
