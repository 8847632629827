.EventBanner {
  display: flex;
  border-radius: 16px;
  width: 100%;
  max-width: 1000px;
  margin: -25px auto 45px auto;

  // filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.2));

  font-family: "Poppins", sans-serif;
  font-style: normal;

  .__inner {
    flex: 1;
    display: flex;
    height: 339px;
    border-radius: 16px;
    overflow: hidden;
  }

  .__preview {
    flex: 1;
    position: relative;
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .__info {
    width: 297px;
    background: #1e232d;
    border-radius: 16px;
    overflow: hidden;
    padding: 40px 26px 30px 26px;

    &__inner {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .__org {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 300;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 11px;

        &__img {
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0 10px 0 0;
        }
      }
      .__title {
        color: #b4bcc3;
        font-weight: bold;
        font-size: 36px;
        line-height: 51px;
        margin-bottom: 17px;
      }
      .__date,
      .__location {
        display: flex;
        color: #b4bcc3;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;

        .__icon {
          width: 25px;
          margin: 0 7px 0 0;

          svg {
            width: 21px;
          }
        }
      }

      .__date {
        margin: 0 0 20px 0;
      }
    }
  }

  @media (max-width: 1250px) {
    .__inner {
      height: 300px;
    }

    .__info {
      width: 300px;
      padding: 20px;

      &__details {
        .__org {
          font-size: 12px;
          margin-bottom: 5px;
        }
        .__title {
          font-size: 20px;
          line-height: 28px;
          min-height: 28px;
          margin-bottom: 10px;
        }
        .__date,
        .__location {
          font-size: 13px;
          line-height: 20px;

          svg {
            transform: scale(0.9);
          }
        }
        .__location {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 1050px) {
    padding: 0;
    border-radius: 0;
    background: transparent;

    .__inner {
      display: block;
      border-radius: 0;
      height: auto;
    }

    .__preview {
      position: relative;
      height: 240px;
      background: #2a2d3a;
      border-radius: 10px;
    }
    .__info {
      width: 100%;
      padding: 16px 0;
      border-radius: 0;
      background-color: transparent;

      &__inner {
        flex-direction: row;
      }
    }
  }

  @media (max-width: 800px) {
    .__info {
      &__inner {
        position: relative;
      }
    }
  }

  @media (max-width: 600px) {
    .__info {
      &__inner {
        flex-direction: column;
      }
    }
  }
}

.EventReview {
  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__expand {
    width: 800px;
    margin: 0 auto;

    &.__isFree {
      width: 600px;
    }
  }

  &__item {
    display: flex;
    min-width: 155px;

    .__state {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 0 0;

      svg {
        font-size: 20px;
      }
    }

    .__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      color: rgba(38, 40, 45, 0.73);
      font-family: "Poppins", sans-serif;
      font-style: normal;
      letter-spacing: 1.2px;

      .__label {
        font-size: 11px;
        line-height: 16px;
        font-weight: 700;
      }

      .__sub {
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }

  &__content {
    margin: 0 0 49px 0;
  }

  &__title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 54px;
    text-align: center;
    padding: 0 40px;
    margin: 0 0 0px 0;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
    overflow: hidden;
  }

  &__link,
  &__sub {
    color: #212b36;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    padding: 0 40px;
    margin: 0 auto 40px auto;
  }

  &__link {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: -20px auto 0 auto;
    max-width: 80%;
    overflow: hidden;

    color: #212b36;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    .__inner {
      display: flex;
      background: rgba(180, 188, 195, 0.21);
      border-radius: 12px;
      padding: 11px 25px;
      transition: all 0.3s ease;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      cursor: pointer;

      &:hover {
        filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.2));
      }
    }

    .__label {
      color: #263239;
      text-align: center;
      display: inline-block;
      width: 105px;
      margin: 0 10px 0 0;
    }

    .__url {
      flex: 1;
      color: #212b36;
      font-weight: 300;
      text-align: left;
      letter-spacing: 1.2px;

      // max-width: calc(100% - 105px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__link_device {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    max-width: 80%;
    overflow: hidden;

    color: #212b36;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    .__inner {
      display: flex;
      background: rgba(180, 188, 195, 0.21);
      border-radius: 12px;
      padding: 10px 15px;
      transition: all 0.3s ease;
      
      cursor: pointer;

      &:hover {
        filter: drop-shadow(0px 8px 28px rgba(0, 0, 0, 0.2));
      }
    }

  }
}
