.EventSectionSubTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .__content {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;

    &__title {
      color: #637381;
      font-size: 20px;
      line-height: 22px;
      font-weight: 600;
      margin: 0 0 10px 0;
    }

    &__sub {
      color: #333;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .__extra {
  }

  @media (max-width: 750px) {
  }
}

.__reg__link {
  color: #00ab55;
  padding: 0 0 0 3px;
  text-decoration: underline;
  cursor: pointer;
}
