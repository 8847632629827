.EventStages {
  &__cards {
    // margin: 0 -15px;

    .StageCard {
      width: 100%;
      margin: 0 0 10px 0;

      &__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px 15px 10px;

        .__btn {
          height: 30px;
          background-color: transparent;
          box-shadow: unset !important;
          border: 1px solid transparent;
          border-radius: 8px;
          border-radius: 5px;

          color: #637381;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 22px;

          &.__del {
            border-color: rgba(145, 158, 171, 0.32);
            margin: 0 10px 0 0;
          }

          &.__edit {
            color: #fff;
            background-color: #00ab55;
          }

          &.__studio {
            background-color: #db1f35;
            width: 30px;
            min-width: 30px;
            max-width: 30px;
            padding: 0;

            img {
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
}
