.AddSponsor {
  .EntryFile .MuiInputBase-input {
    padding: 18px 14px;
  }

  &__photo {
    position: absolute;
    top: 80px;
    left: 0;
    margin: 0 20px;
    z-index: 99;

    @media (max-width: 850px) {
      top: 70px;
      left: 50%;
      transform: translate(-50%, 0);
      margin: 0;

      &__inner {
        .__label {
          font-size: 9px;
        }
      }
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
    width: 100%;

    .FileAdd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px 0;

      input {
        position: absolute;
        z-index: -99;
      }

      .__label {
        font-family: "Poppins", sans-serif;
        color: #333;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
      }

      .__icon {
        display: flex;
        align-items: center;
        cursor: pointer;


        font-family: "Poppins", sans-serif;
        font-size: 13px;

        svg {}
      }
    }

    .FileItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // background: #f4f6f8;
      border-bottom: solid 1px rgba(145, 158, 171, 0.1);
      padding: 10px 0;
      margin: 0 0 10px 0;

      .__label {
        display: flex;
        align-items: center;

        .__name {
          font-family: "Poppins", sans-serif;
          color: #333;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
        }

        svg {
          display: inline-block;
          transform: scale(0.8);
          margin: 0 7px 0 0;
        }
      }

      .__del {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
          color: #ff4842;
          cursor: pointer;
        }
      }
    }
  }
}

.SponsorSearch {
  width: 100%;
  padding: 7px 0 0 32px;

  &__list {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    &.__new {
      // font-size: 13px;
      color: #00ab55;
    }
  }
}