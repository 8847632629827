.EventPrediction {
  background-color: #FBFBFC;

  &__LSide{
    padding: 20px;
  }

  &__header{
    color: #637381;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    margin: 20px;
  }

  &__Box{
    //margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 17px;
    gap: 20px;

    width: 235px;
    height: 119px;
    
    background: #FFFFFF;
    box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.03);
    border-radius: 12px;

    &__LSide{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 5px;
      width: 136px;
      height: 97px;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    &__Typo{
      width: 136px;
      height: 44px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #212B36;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    &__Count{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 13px;
      height: 48px;
      flex: none;
      order: 1;
      flex-grow: 0;

      svg{
        width: 24px;
        height: 24px
      }
    }

    &__Digit{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #212B36;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }

  &__RSide{
    
    g.text-group{
      text{
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 20px !important;
        line-height: 40px !important;
      }
    }
  }

  @media (max-width: 1280px) {
    
    
  }
}
