.EstimationSet {
  height: calc(100vh - 260px);

  .EntryFile .MuiInputBase-input {
    padding: 18px 14px;
  }

  .EventButton {
    min-width: 100px;
    height: 45px;
    margin: 0 20px;
    background-color: #00AB55;

    &__cancel{
      border: 1 #777777;
      color: #777777;
      background-color: transparent;
    }
  }

  &__foolter{
    direction: rtl;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &__sep {
    width: 100;
    height: 1px;
    background: #d9d9d9;
    margin: 60px 0;
  }

  &__title {
    margin: 70px 0 30px 0;
    height: 24px;

    svg{
      margin-bottom: -4px;
    }

    &__text{
      color: #333333;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;
      height: 24px;
    }

    &.__ {
      margin: 0 0 20px 0;
    }

    &.__sub {
      color: #637381;
      margin: 0 0 25px 0;

      &.__ {
        margin: 0 0 50px 0;
      }
    }



    @media (max-width: 850px) {
      font-size: 13px;
      margin: 0 0 15px 0;
    }
  }
}
