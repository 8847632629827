.MuiFormControl-root {
  // position: relative;
  margin: 0;

  .MuiInputLabel-root {
    font-family: "Poppins";
    color: #454545;
    font-size: 16px;
    font-size: 14px;
    font-weight: 600;
  }
}

.Entry {
  &.__required {
    .MuiInputLabel-root {
      color: #454545;

      &::after {
        content: " *";
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 300;
        color: red;
      }
    }
  }
}

.EntryCheckbox {
  .MuiFormControlLabel-label {
    color: #637381;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
  }
}

.EntryColor {
  position: relative;

  &__preview,
  &__value,
  &__label {
    position: absolute;
    top: -25px;
    left: 0;

    font-family: "Poppins", sans-serif;
    color: #637381;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
  }

  &__preview {
    top: 10px;
    left: 10px;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    box-shadow: rgba(145, 158, 171, 0.25) 0px 0px 5px;
  }

  &__value {
    top: 20px;
    left: 65px;

    color: #212b36;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
  }

  label {
    font-family: "Poppins", sans-serif;
    color: #333;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    pointer-events: none;
    cursor: pointer;
  }

  input {
    opacity: 0;
    text-indent: -999em;
    cursor: pointer;
  }

  &__name,
  &__btn {
    position: absolute;
    pointer-events: none;
  }

  &__name {
    top: 18px;
    left: 0;
    margin: 0 0 0 14px;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;

    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.EntryImage,
.EntryFile {
  position: relative;

  &__label {
    position: absolute;
    top: -25px;
    left: 0;

    font-family: "Poppins", sans-serif;
    color: #333;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;

    &.__required {
      &::after {
        content: " *";
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        color: red;
      }
    }
  }

  .EntryText {
    .MuiInputLabel-root {
      font-weight: 400;
    }

    .MuiInputBase-input {
      width: calc(100% - 150px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    text-indent: -999em;
    z-index: -999;
  }

  &__actions {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 99;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .__btn {
      display: flex;
      width: 110px;
      height: 40px;
      color: #fff;
      font-size: 14px;
      background: #00ab55;

      &:hover {
        background: #00ab55;
      }
    }

    &__upload {
      .__btn {
        background: #00ab55;

        &:hover {
          background: #00ab55;
        }
      }
    }

    &__reset {
      .__btn {
        background: #ff4842;

        &:hover {
          background: #ff4842;
        }
      }
    }
  }

  &__preview {
    margin: 20px 0 0 0;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  @media (max-width: 850px) {
  }
}

.EntryPhoto {
  position: relative;
  border-radius: 18px;
  background: rgba(9, 137, 150, 0.1);
  background: #f4f6f8;
  height: 140px;
  margin: 0 0 40px 0;
  overflow: hidden;
  cursor: pointer;

  &.__isReadOnly {
    cursor: default;
  }

  &:not(.__isReadOnly):hover {
    .__img {
      display: none;
    }
  }

  .__img {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s ease;

    .__input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;

      .EntryImage {
        opacity: 0.5;
        visibility: hidden;
      }
    }

    .__icon {
      margin: 0 0 10px 0;
    }

    .__label {
      color: #637381;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: center;

      &.__required {
        &::after {
          content: " *";
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
          color: red;
        }
      }
    }
  }

  @media (max-width: 850px) {
    height: 100px;
    margin: 0 0 70px 0;

    &__inner {
      margin: 20px 0 0 0;
      justify-content: flex-start;

      .__icon {
        margin: 0 0 5px 0;
      }

      .__label {
        font-size: 9px;
      }
    }
  }

  &.__isRounded {
    border-radius: 50%;
    height: 140px;
    width: 140px;
    border: 1px dashed rgba(145, 158, 171, 0.32);
    overflow: hidden;
    padding: 4px;
    cursor: pointer;

    .__img {
      border-radius: 50%;
      overflow: hidden;
    }

    .__inner {
      border-radius: 50%;
    }

    @media (max-width: 850px) {
      height: 100px;
      width: 100px;
      padding: 3px;

      .__inner {
        .__label {
          font-size: 9px;
        }
      }
    }
  }
}

.EntryAutocomplete {
  position: relative;

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  &__helper {
    position: absolute;
    top: -30px;
    left: 4px;

    color: #333;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }

  .MuiAutocomplete-input {
    padding: 7.5px 85px 7.5px 6px !important;
  }

  &__chips {
    margin: 10px -5px 0 -5px;

    .MuiChip-root {
      background: transparent;
      border: 1px solid #00ab55;
      margin: 0 5px 5px 5px;

      .MuiChip-label {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        color: #00ab55;
        min-width: 70px;
        padding-top: 5px;
      }

      svg {
        color: #00ab55;
      }
    }
  }

  .__action {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .__btn {
      color: #fff;
      background: #00ab55;
      width: 100px;
      height: 40px;

      &:hover {
        color: #fff;
        background: #00ab55;
      }
    }
  }

  .__with-checkbox {
  }
}

.chrome-picker {
  // width: 400px !important;
  // border: 10px solid red;
  & > div:nth-child(2) {
    & > div:nth-child(1) {
      & > div:nth-child(1) {
        display: none !important;
      }
      & > div:nth-child(2) {
        & > div:nth-child(1) {
          margin: 0 !important;
        }
        & > div:nth-child(2) {
          display: none !important;
        }
      }
    }
    & > div:nth-child(2) {
      display: none !important;
    }
  }
}

.EntryDateButton {
  position: relative;

  &__trigger {
    position: relative;
  }

  &__inp {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.EntryDateButtonPopover {
  .MuiBackdrop-root {
    background-color: transparent !important;
  }
}

.EntryTextLimit {
  color: #637381;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;

  position: absolute;
  right: 10px;

  .__sep {
    display: inline-block;
    font-size: 9px;
    margin: 0 3px !important;
  }
}

textarea {
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #363636;
  }
}

//
//# Froala Base Editor
//

.EntryEditor {
  max-height: 800px;
  
  .MuiInputLabel-root {
    background-color: #fff;
    padding: 0 10px 0 4px;
    margin: 0 0 0 -2px;
    z-index: 99;
  }

  &.__embeded {
    .BaseMarkdown.rc-md-editor {
      min-height: 150px;
    }
  }
  &.__height1 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 200px;
    }
  }
  &.__height2 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 250px;
    }
  }
  &.__height3 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 300px;
    }
  }
  &.__height4 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 350px;
    }
  }
  &.__height5 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 400px;
    }
  }
  &.__height6 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 450px;
    }
  }
  &.__height7 {
    .BaseMarkdown.rc-md-editor,
    .rse-StyleEditor-root {
      min-height: 500px;
    }
  }
}

.BaseFroala {
  .fr-box {
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
    box-shadow: none;

    .fr-toolbar {
      border: none;
      box-shadow: none;
      border-radius: 8px 8px 0 0;

      .fr-btn-grp {
        padding: 7px 10px;
        margin: 0;

        .fr-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 25px;
          margin: 0;

          .fr-svg {
            width: 18px;
            height: 18px;
            margin: 0;
          }

          &.fr-active:not(.fr-dropdown) .fr-svg path {
            fill: #00ab55;
          }
        }
      }

      .fr-newline {
      }

      .fr-more-toolbar {
        &.fr-expanded {
          height: 30px;
          padding: 0 10px !important;
        }

        .fr-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          // text-align: center;
          width: 30px;
          height: 25px;
          margin: 0;

          .fr-svg {
            width: 18px;
            height: 18px;
            margin: 0;
          }
        }
      }
    }

    .fr-sticky-dummy {
    }

    .fr-wrapper {
      display: flex;
      border: none !important;
      box-shadow: none !important;
      border-radius: 0 0 8px 8px !important;
      min-height: 84px;
      height: 100%;
      padding: 0;
      overflow: hidden;

      & > div:not(.fr-element) {
        display: none;
      }

      .fr-element {
        flex: 1;
        padding: 10px 15px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        height: 100%;
        max-height: 150px;

        //# HTML Elements
        color: #333;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        ul,
        ol {
          padding: 0 0 0 40px;
        }

        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #363636;
        }
      }

      .fr-counter {
        display: none;
      }
    }

    .fr-second-toolbar {
      display: none;
    }
  }
}

.custom-label {
  color: #333;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  &.required:after {
    content: " *";
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 300;
    color: red;
  }
}

.BaseMarkdown.rc-md-editor {
  width: 100%;
  min-height: 125px;
  min-height: 217px;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);

  &.full {
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0;
    border: none;
    z-index: 9999;
  }

  //# Header
  .rc-md-navigation {
    background-color: #fff;
    border-bottom: 1px solid rgba(145, 158, 171, 0.2);
    height: 35px;
    min-height: 35px;

    .navigation-nav {
      height: 35px;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 20px;
        min-width: 20px;
        max-width: 20px;

        height: 24px;
        min-height: 24px;
        max-height: 24px;

        .rmel-iconfont {
          color: #333;
          font-size: 16px;
        }

        .header-list {
          .list-item {
            padding: 5px;
            width: 70px;

            h1 {
              font-size: 22px;
            }
            h2 {
              font-size: 20px;
            }
            h3 {
              font-size: 18px;
            }
            h4 {
              font-size: 16px;
            }
            h5 {
              font-size: 14px;
            }
            h6 {
              font-size: 12px;
            }
          }
        }
      }

      &.left {
        .button-wrap {
          .button {
          }
        }
      }

      &.right {
        .button-wrap {
        }
      }
    }

    @media (min-width: 510px) {
    }
  }

  //# Container
  .editor-container {
    .section {
      &.sec-md {
      }

      &.sec-html {
        position: relative;
        border-left: 1px solid rgba(145, 158, 171, 0.2);

        &::after {
          content: "Preview";
          position: absolute;
          bottom: 0px;
          right: 0px;
          padding: 3px 5px;

          color: #76d72f;
          // color: #333;
          font-size: 12px;
          font-family: monospace;
          background-color: #d5efe3;
          border-radius: 1px;
        }
      }
    }
  }
}
