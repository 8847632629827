.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: grey;
  cursor: pointer;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.MuiModal-root,
.MuiDrawer-root {
  .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5) !important;
    background: rgba(0, 0, 0, 0.4) !important;
  }
}

.MuiMenu-root {
  .MuiBackdrop-root {
    background: rgba(99, 37, 37, 0) !important;
  }
}

.SidebarContent {
  display: flex;
  flex-direction: column;

  .__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 49px;
    width: 51px;
    min-width: 51px;
    max-width: 51px;
    background: rgba(14, 140, 147, 0.06);
    border-radius: 12px;

    text-align: center;

    .__day {
      color: #0e8c93;
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      margin: 0 0 3px 0;
    }
    .__month {
      // color: #b4bcc3;
      color: #0e8c93;
      font-size: 9px;
      line-height: 10px;
      font-weight: 500;
    }
  }

  .__details {
    flex: 1;
    font-family: "Poppins";
    overflow: hidden;

    &__title {
      color: #3d4349;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    &__preview {
      color: #00ab55;
      font-size: 12px;
      line-height: 22px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.SnackbarItem-contentRoot {
  // border: 10px solid red;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.05), 0px 6px 10px 0px rgba(0, 0, 0, 0.05),
    0px 1px 18px 0px rgba(0, 0, 0, 0.05) !important;
}

.DrawerRequired {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 0 0;

  &__info {
    display: flex;
    align-items: center;
    color: red;
    font-size: 13px;

    .__icon {
      color: inherit;
      font-size: 20px;
      margin: 0 7px 0 0;
    }

    .__label {
      line-height: 20px;
    }
  }
}

.__stripe-connected {
  padding: 13px 12px 13px 16px;
  max-width: 380px;
  background: rgba(230, 39, 62, 0.21);
  border: 1px solid #db1f35;
  border-radius: 8px;

  .__inner {
    display: flex;

    color: #db1f35;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    .__icon {
      margin: 0 12px 0 0;
    }

    .__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .__setting {
        font-weight: 500;

        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.__matchmaking {
  margin: 20px 0 0 0;

  &__top {
    color: #454545;
    font-family: "Poppins";
    font-style: normal;
    margin: 0 0 40px 0;

    .__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 28px;
    }

    .__sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      span {
        color: #00ab55;
        padding: 0 4px;
        cursor: pointer;
      }
    }
  }
}

.DashboardNavbar {
}

.DashboardNavbarPopover {
  &__org {
    &:hover {
      color: #00ab55;
      cursor: pointer;
    }
  }

  &__event {
  }
}
