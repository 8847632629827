.ModalValidation {
  position: fixed;
  z-index: 9999;
  // ...
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    font-family: "Poppins", sans-serif;
    position: relative;
    background-color: #fff;
    z-index: 9;
    width: 450px;
    border-radius: 5px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 15px 20px;

    .__title {
      color: #363636;
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
    }
  }

  &__body {
    color: #363636;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 10px 20px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;

    .__yes-btn {
      background-color: #263239;
      background-color: #ff4842;
      box-shadow: 0px 8px 16px rgba(82, 82, 82, 0.15);
      min-width: 100px;

      .MuiButton-label {
        color: #fff;
      }
    }

    .__no-btn {
      background-color: transparent;
      box-shadow: unset;
      min-width: 100px;
      margin: 0 5px 0 0;

      .MuiButton-label {
        color: #263239;
      }
    }
  }
}

.CropImage {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Poppins", sans-serif;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 9;

    display: flex;
    flex-direction: column;
  }

  &__body {
    width: 100vw;
    height: calc(100vh - 58px);
    background-color: #222;
    padding: 20px;
    overflow-x: auto;
    overflow-y: auto;

    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ReactCrop {
      position: relative;
      display: flex;
      background-color: #fff;
      overflow: unset;
      max-width: 100%;
      max-height: 100%;

      &__child-wrapper {
        display: flex;
        height: unset;
        width: unset;
        max-height: unset;
        max-width: unset;

        img {
          display: inline;
          height: unset;
          width: unset;
          max-height: unset;
          max-width: unset;
        }
      }

      &__crop-selection {
        box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.6);

        .ReactCrop__drag-elements {
          .ReactCrop__drag-handle {
            &:after {
              background-color: #00ab55;
              border: 1px solid #00ab55;
            }
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    height: 58px;
    min-height: 58px;
    max-height: 58px;
    background-color: #222;
    border-top: 1px solid #ffffff40;

    .__meta {
      flex: 1;
      color: #fff;
      font-size: 14px;
      padding: 0 20px 0 0;
    }

    .__actions {
      .__yes-btn {
        background-color: #00ab55;
        box-shadow: 0px 8px 16px rgba(82, 82, 82, 0.15);
        min-width: 100px;
        height: 36px;

        .MuiButton-label {
          color: #fff;

          .ball-loader {
            &-ball {
              background-color: #fff;
            }
          }
        }
      }

      .__no-btn {
        background-color: transparent;
        box-shadow: unset;
        min-width: 100px;
        margin: 0 5px 0 0;

        .MuiButton-label {
          color: #fff;
        }
      }
    }

    @media (max-width: 500px) {
      justify-content: flex-end;

      .__meta {
        display: none;
      }
    }
  }
}
