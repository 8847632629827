.EventSectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 60px;

  .__content {
    display: flex;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    margin: 0 20px 0 0;

    &__icon {
      margin: 0 10px 0 0;
    }

    &__event {
      &__title {
        color: #454545;
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        margin: 3px 0;
      }

      &__sub {
        color: #637381;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.2px;
        font-weight: 300;
      }
    }
  }

  .__status {
    display: flex;
    cursor: pointer;

    .EventButton {
      min-width: 200px;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fcf1d2;
      border-radius: 8px;
      height: 43px;
      width: 43px;
      margin: 0 9px 0 0;

      &.isPublished {
        background: #d5efe3;
      }
    }

    &__content {
      color: #637381;
      font-family: "Poppins", sans-serif;
      font-style: normal;

      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-size: 10px;
        line-height: 24px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  &.__sub {
    .__content {
      &__icon {
        display: none;
      }

      &__event {
        &__title {
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .__content {
      margin: 0 0 30px 0;

      &__icon {
        width: 40px;
      }

      &__event {
        &__title {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }

    &.__sub {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;

      .EventButton {
        min-width: 100px;
      }
    }
  }
}
