.EventTicketPreview {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    height: 92px;
    min-height: 92px;
    padding: 0 40px;
    background-color: rgba(255, 255, 255, 0.72);
    border-bottom: 1px solid #e3e3e3;

    &__logo {
      flex: 1;
    }

    .EventButton {
      width: 135px;
      min-width: 135px;
      height: 40px;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #ff4842;

      .MuiButton-label {
        font-size: 15px;
        line-height: 26px;
      }
    }
  }

  &__content {
    width: 100%;
    height: calc(100vh - 92px);
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #363636;
    }

    .__inner {
      .__group {
        flex: 1;
        flex-grow: 1;
        display: flex;

        &.__top {
        }

        &.__bottom {
        }
      }
    }
  }
}
