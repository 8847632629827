.EventEntityDrawer {
  .Entry {
    .MuiInputLabel-root {
      font-size: 14px;
    }

    .MuiInputBase-root {
      font-size: 14px;
    }
    .MuiInputBase-input {
      padding: 15px 14px;
    }

    textarea {
      padding: 0 !important;
    }
  }

  &__inner {
    flex: 1;
    position: relative;
    padding: 50px 56px 50px 56px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;

    .__close {
      position: absolute;
      top: 30px;
      right: 30px;
    }

    @media (max-width: 768px) {
      padding: 20px;

      .__close {
        top: 10px;
        right: 10px;
      }
    }
  }

  &__header {
    color: #637381;
    font-family: "Montserrat", sans-serif;
    font-style: normal;

    h2 {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 14px;
      line-height: 14px;
      margin: 0;
    }

    margin: 0 0 30px 0;
  }
  &__content {
    position: relative;
    margin: 0 0 30px 0;
  }
  &__actions {
  }

  &__field__explain{
    color: #637381;
    font-size: 12px;
  }
}
