.TicketPrintable {
  display: none;
  flex-direction: column;

  @media print {
    display: flex;
    flex-direction: column;

    width: 210mm;
    height: 297mm;
    min-height: 297mm;
    max-height: 297mm;
    overflow: hidden;
    padding: 0;
    margin: 0;

    html,
    body {
      width: 210mm;
      height: 297mm;
    }

    @page {
      size: A4;
      margin: 0;
    }

    .__group {
      flex: 1;
      flex-grow: 1;
      display: flex;

      &.__top {
        flex: 1;
        height: 148.5mm;
        min-height: 148.5mm;
        max-height: 148.5mm;
      }

      &.__bottom {
        flex: 1;
        height: 148.5mm;
        min-height: 148.5mm;
        max-height: 148.5mm;
      }
    }
  }
}

// Ticket Top
.TicketPrintableTop {
  flex: 1;
  background: transparent;
  padding: 10mm 6mm 27mm 6mm;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;

  .__logo {
    width: 25mm;
    height: 25mm;
    margin: 0 0 5mm 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .__title {
    color: inherit;
    font-size: 10mm;
    font-size: 8mm;
    line-height: 14mm;
    font-weight: 700;
    margin: 0 0 14mm 0;
    margin: 0 0 5mm 0;
  }

  .__details {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 166mm;
    margin: 0 0 14mm 0;
    margin: 0 0 7mm 0;

    .__details__body {
      flex: 1;
      display: flex;
      padding: 6mm 5mm 5mm 5mm;
      border-radius: 5mm 5mm 0 0;
      border: 0.5mm solid #dfdfdf;
      border-bottom: none;

      .__info {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 75%;
        color: inherit;
        overflow-wrap: break-word;

        .__info__num {
          font-size: 4mm;
          line-height: 4mm;
          font-weight: 500;
          margin: 0 0 3mm 0;

          .__label {
          }

          .__value {
            font-size: 4mm;
            font-weight: 400;
          }
        }

        .__info__name {
          font-size: 6mm;
          line-height: 6mm;
          font-weight: 700;
          margin: 0 0 3mm 0;
        }

        .__info__pos {
          font-size: 4mm;
          line-height: 4mm;
          font-weight: 300;
          margin: 0 0 3mm 0;
        }

        .__info__org {
          font-size: 4mm;
          line-height: 4mm;
          font-weight: 300;
        }
      }

      .__qr {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        background-color: white;
        height: fit-content;

        svg {          
          width: 100%;
          margin: 1mm;
          height: fit-content;
        }
      }
    }

    .__details__type {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ff8a00;
      border-radius: 0 0 5mm 5mm;
      height: 26mm;
      height: 16mm;

      color: #f8f8fa;
      font-size: 7mm;
      line-height: 7mm;
      font-weight: 700;
    }
  }

  .__date {
    color: inherit;
    font-size: 4mm;
    line-height: 4mm;
    font-weight: 400;
    max-width: 105mm;
    margin: 0 0 5mm 0;
  }

  .__who {
    color: inherit;
    font-size: 4mm;
    line-height: 4mm;
    font-weight: 600;
  }
}

//# Ticket Bottom
.TicketPrintableBottom {
  flex: 1;
  padding: 10mm 6mm 27mm 6mm;

  .__title {
    color: inherit;
    font-family: "Poppins";
    font-style: normal;
    font-size: 8mm;
    line-height: 8mm;
    font-weight: 700;
    margin: 0 0 8mm 0;
  }

  .__partners {
    display: flex;

    &__item {
      margin: 0 1mm;
    }
  }
}
