.TicketStep {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin: 0 0 100px 0;

  &.__details,
  &.__print {
    padding: 70px 0 0 0;

    .__groups {
      display: flex;

      .__sep {
        width: 1px;
        background: #d9d9d9;
        margin: 40px 60px;
      }

      .__group {
        flex: 1;

        font-family: "Poppins";
        font-style: normal;

        &__title {
          color: #454545;
          font-size: 14px;
          line-height: 28px;
          font-weight: 700;
          margin: 0 0 3px 0;
        }

        &__sub {
          color: #454545;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin: 0 0 40px 0;

          &.__steps {
            margin: 0 0 11px 0;

            .__btn {
              color: #00ab55;
              text-decoration: underline;
              cursor: pointer;
            }
          }

          &.__bold {
            color: red;
          }
        }

        &__note {
          display: flex;
          background: #d0f2ff;
          border: 1px solid #74caff;
          border-radius: 8px;
          padding: 13px;
          margin: 0 0 20px 0;

          color: #04297a;
          font-family: "Poppins";
          font-style: normal;

          .__icon {
            display: flex;
            justify-content: center;
            width: 50px;

            svg {
              color: #1890ff;
            }
          }

          .__content {
          }

          .__title {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            width: 70%;
            margin: 0 0 5px 0;
          }

          .__sub {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin: 0;
          }
        }

        .MuiGrid-container {
          width: calc(100% + 32px);
          margin-top: -32px;
          margin-left: -32px;
          margin-bottom: 0px;
        }
      }

      @media (max-width: 850px) {
        flex-direction: column;

        .__sep {
          display: none;
        }

        .__group {
          margin: 0 0 40px 0;
        }
      }
    }
  }

  &.__design,
  &.__print {
    padding: 0;

    .__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 80px 0;

      .__content {
        font-family: "Poppins";
        font-style: normal;

        .__title {
          color: #454545;
          font-weight: 700;
          font-size: 14px;
          line-height: 28px;
          margin: 0 0 3px 0;
        }

        .__sub {
          color: #454545;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin: 0;
        }
      }

      .__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 164px;
        height: 49px;
        background: rgba(99, 115, 129, 0.06);
        border-radius: 8px;
        cursor: pointer;

        .__icon {
          display: flex;
          margin: 0 10px 0 0;
        }

        .__label {
          color: #0e8c93;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  &.__design {
    .__groups {
      display: flex;

      .__sep {
        width: 1px;
        background: #d9d9d9;
        margin: 40px 60px;
      }

      .__group {
        flex: 1;

        &__title {
          color: #454545;
          font-size: 14px;
          line-height: 28px;
          font-weight: 700;
          margin: 0 0 3px 0;
        }

        &__sub {
          color: #454545;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          margin: 0 0 40px 0;

          .__nav {
            color: #00ab55;
            cursor: pointer;
          }
        }

        &__note {
          display: flex;
          background: #d0f2ff;
          border: 1px solid #74caff;
          border-radius: 8px;
          padding: 13px;
          width: 100%;
          max-width: 436px;
          margin: 0 0 40px 0;

          color: #04297a;
          font-family: "Poppins";
          font-style: normal;

          .__icon {
            display: flex;
            justify-content: center;
            width: 50px;

            svg {
              color: #1890ff;
            }
          }

          .__content {
          }

          .__title {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            margin: 0 0 5px 0;
          }

          .__sub {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            user-select: none;
            margin: 0;

            .__clickable {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        &.__qr-bar {
          .__qr-bar {
            display: flex;
            flex-direction: column;
            width: 436px;
            height: 256px;
            border-radius: 24px;
            background: rgba(180, 188, 195, 0.1);
            overflow: hidden;

            .__body {
              flex: 1;
              display: flex;
              padding: 32px 32px 20px 32px;

              .__info {
                flex: 1;
                display: flex;
                flex-direction: column;

                color: #212b36; // TODO: inherit this

                .__info__num {
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 500;
                  margin: 0 0 14px 0;

                  .__value {
                    font-weight: 400;
                  }
                }

                .__info__name {
                  font-size: 24px;
                  line-height: 22px;
                  font-weight: 700;
                  text-transform: capitalize;
                  margin: 0 0 14px 0;
                }

                .__info__pos {
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 300;
                  text-transform: capitalize;
                  margin: 0 0 14px 0;
                }

                .__info__org {
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 300;
                  text-transform: capitalize;
                }
              }

              .__qr {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                img {
                  width: 30mm;
                }
              }
            }

            .__type {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ff8a00;
              height: 60px;

              color: #f8f8fa;
              font-size: 24px;
              line-height: 22px;
              font-weight: 700;
            }
          }
        }
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;

      .__sep {
        display: none;
      }

      .__group {
        margin: 0 0 40px 0;
      }
    }
  }

  &.__print {
    padding: 0;

    .__header {
      margin: 0 0 80px 0;
    }

    .__body {
      .EventTicketPreview {
        width: 100%;
        height: auto;

        &__header {
          display: none;
        }

        &__content {
          height: auto;
          overflow-y: unset;
          overflow-x: unset;
        }
      }
    }
  }
}
